<template>
  <div
    v-if="enabled"
    id="navCartForm"
    :class="[openClass, 'navbar-cart-form', 'stripe-elements']"
  >
    <span
      class="empty-section"
      @click="hideCart"
    />

    <div class="navbar-vue-cart">
      <div class="header-section">
        <h3 class="header-title">
          {{ $t('navCart.yourItems') }}
        </h3>
        <div class="currency-and-close">
          <button
            v-if="cartDisabled || depositOrder"
            class="btn clear-cart"
            @click="clearCart"
          >
            Clear Cart
          </button>
          <CurrencyDropdown :currency="currency" />
          <button
            class="btn close-cart"
            @click="hideCart"
          >
            {{ $t('navCart.close') }}
          </button>
        </div>
      </div>

      <Cart
        ref="cart"
      />

      <div
        v-if="isXGames()"
        class="checkout-buttons"
      >
        <button
          v-if="(order.total > 0 || discountCovers)&& !hotelNeeded"
          id="proceed-to-checkout"
          class="btn btn-primary proceed-to-checkout"
          :disabled="invalidItems.length > 0"
          @click="goToCheckout"
        >
          {{ $t('navCart.continue') }}
        </button>
        <button
          class="btn btn-primary continue-shopping"
          @click="hideCart"
        >
          {{ $t('navCart.keepShopping') }}
        </button>
      </div>
      <div
        v-else
        class="checkout-buttons"
      >
        <button
          v-if="(order.total > 0 || discountCovers)&& !hotelNeeded"
          id="proceed-to-checkout"
          class="btn proceed-to-checkout"
          :disabled="invalidItems.length > 0"
          @click="goToCheckout"
        >
          {{ $t('navCart.continue') }}
        </button>
        <button
          class="btn continue-shopping"
          @click="hideCart"
        >
          {{ $t('navCart.keepShopping') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cart from '../../cart/cart'
import CurrencyDropdown from '../../../default/currency_convert/currency-dropdown'
import CurrencyApi from '../../../default/currency_convert/currency-api'

import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  components: {
    Cart,
    CurrencyDropdown
  },

  data() {
    return {
      amount: 0,
      checkoutLink: '/cart/checkout',
      currency: 'USD',
      enabled: true,
      hotelNeeded:false,
      navLinkTag: null
    }
  },

  computed: {
    ...mapGetters('cart', [
      'getCartDisplay',
      'openClass'
    ]),
    discountCovers(){
      return (this.order.discount > 0 && this.order.discount >= this.order.total)
    },
    cartDisabled(){
      return this.order.disabled_cart
    },
    order(){
      return this.$store.state.order.order
    },
    depositOrder(){
      return this.order.items && this.order.items.find( i=> i.deposit)
    },
    invalidItems() {
      if (this.order) {
        return this.order.items.filter(item => {
          return (
            item.hotel_id == null && item.package_hotel_required ||
            item.price_change
          )
        })
      } else {
        return []
      }
    },
  },

  created() {
    if (this.chargeablecurrencies == undefined) {
      // Use all currencies for Event Pages
      this.fetchCurrencies()
    } else {
      // Only use chargeableCurrencies for cart/order vues
      this.getCurrentCurrency()
      this.updateCurrentCurrency(['USD'].concat(this.chargeablecurrencies))
    }
    let el = document.getElementById('gaPathOverride')
    if(el) { var statePath = el.dataset.url }
    this.navLinkTag = document.getElementById('navCartLink')

    if(statePath != '/cart/login') {
      if (document.getElementById('cartNavCheck') == null) {
        this.navLinkTag.addEventListener('click', this.displayCart)
      } else {
        this.checkoutLink = '/cart/summary'
        this.navLinkTag.href = this.checkoutLink
        this.enabled = false
      }
    }
  },

  methods: {
    ...mapMutations('cart', ['displayCart','hideCart']),
    ...mapMutations('order', ['updateCurrencies', 'updateCurrentCurrency', 'updateExchangeRate']),

    fetchCurrencies() {
      CurrencyApi.availableCurrencies().then(response => {
        this.updateCurrencies(response.currencies)
        this.updateCurrentCurrency(response.current_currency)
        this.updateExchangeRate(response.rate)
        // this.currencies = currencies.currencies
        // this.current = currencies.current_currency
        // this.$emit('currency-emit', this.error_message, this.current, Number(response.currencies.rate))
      })
    },

    getCurrentCurrency() {
      CurrencyApi.getCurrentCurrency().then(response => {
        if (response.currency) {
          this.updateCurrentCurrency(response.currency)
          this.updateExchangeRate(response.rate)
          this.$emit('currency-emit', this.error_message, this.current, Number(response.rate))
        }
      })
    },

    isXGames() {
      if(window.name == 'x-games') {
        return true
      }
    },

    clearCart() {
      let cart = this.$refs.cart
      cart.clearCart()
      // must trigger action in nav cart, but set order in cart.vue
    },

    goToCheckout() {
      if (this.invalidItems.length > 0 || this.hotelNeeded) { return }

      window.location.href = window.locale ? `/${window.locale}/cart/summary` : '/cart/summary'
    },
  }
}
</script>
